/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import dynamic from 'next/dynamic';
import trim from 'lodash/trim';
import lowerCase from 'lodash/lowerCase';
import sample from 'lodash/sample';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import sidekickInit from '../../utils/sidekick/init';
import styles from './StandardHero.module.scss';
import { HeroBackgroundImage, ScrollText } from '../Hero/SharedElements';
import { ImagePropTypes } from '../Image';
import HeroPropTypes from '../Hero/SharedElements/Module/HeroPropTypes';
import ErrorBoundary from '../ErrorBoundary';
import StandardHeroContent from './StandardHeroContent';
import ContentModule from '../ContentModule';
import { useAppProvider } from '../../../AppProvider/AppProvider';

const ModuleForm = dynamic(() => import('../ModuleForm'));

export const StandardHeroPropTypes = {
  ...HeroPropTypes,
  images: PropTypes.arrayOf(PropTypes.shape(ImagePropTypes)).isRequired,
  imageTablets: PropTypes.arrayOf(PropTypes.shape(ImagePropTypes)),
  imageMobiles: PropTypes.arrayOf(PropTypes.shape(ImagePropTypes)),
  form: PropTypes.shape({}),
  content: PropTypes.shape({ _contentTypeId: PropTypes.string.isRequired }),
  formAlignment: PropTypes.string,
  anchor: PropTypes.string,
  backgroundColor: PropTypes.string,
  bottomImages: PropTypes.arrayOf(PropTypes.shape(ImagePropTypes)),
  videoDesktop: PropTypes.shape({}),
  videoMobile: PropTypes.shape({}),
  disclaimerTextString: PropTypes.string,
  eventName: PropTypes.string
};

function StandardHero({
  _id,
  _contentTypeId,
  internalTitle,
  textTheme,
  height = 'Full',
  textJustification,
  title,
  subtitle,
  supertitle,
  logo,
  bodyString,
  images,
  imageTablets,
  imageMobiles,
  videoDesktop,
  videoMobile,
  backgroundColor,
  backgroundImageMaxWidth,
  scrollText,
  scrollTextTheme,
  form,
  formAlignment,
  bottomImages,
  content,
  button,
  disclaimerTextString,
  anchor,
  eventName
}) {
  const disclaimerText = disclaimerTextString;
  const body = bodyString;
  const { formStyle } = form || {};
  const centerText = formAlignment === 'Center';
  const hasContent = form || content;
  const hasContentSetting = form?.formSettings || content;
  const { contentGroup } = useAppProvider();
  const { sidekicker } = sidekickInit({ _id, _contentTypeId, internalTitle });

  React.useEffect(() => {
    if (window?.dataLayer && !!eventName && eventName.trim() !== '') {
      window.dataLayer.push({
        event: eventName,
        content_group: contentGroup
      });
    }
  }, [eventName, contentGroup]);

  return (
    <ErrorBoundary>
      <section
        className={cx('hero', styles.heroWrapper, anchor ? styles[anchor.toLowerCase()] : null)}
        id={anchor && anchor.toLowerCase()}>
        <HeroBackgroundImage
          height={lowerCase(height)}
          image={sample(images)}
          imageTablet={sample(imageTablets)}
          imageMobile={sample(imageMobiles)}
          videoDesktop={videoDesktop}
          videoMobile={videoMobile}
          maxWidth={backgroundImageMaxWidth}
          backgroundColor={backgroundColor}
          data-testid="StandardHero-HeroBackgroundImage"
        />
        <div
          data-testid="StandardHero"
          className={cx(
            'standard-hero',
            styles.hero,
            styles[`align-${centerText ? 'center' : lowerCase(textJustification)}`],
            styles[`theme-${lowerCase(textTheme)}`],
            `theme-${lowerCase(textTheme)}`,
            styles[`height-${lowerCase(height)}`],
            !hasContentSetting ? styles.noForm : '',
            'hero'
          )}>
          <div className="container h-100 py-4">
            <div
              className={cx('row h-100', {
                'flex-wrap': hasContent && !centerText,
                'flex-md-nowrap': form && !centerText,
                'align-items-center': !hasContent,
                [styles[`row${formStyle}`]]: hasContent
              })}>
              {hasContentSetting ? (
                <>
                  <div
                    className={cx(
                      'col-12',
                      { 'pt-5 pt-xl-0': hasContent },
                      styles[`content-${formStyle}-${formAlignment}`.toLowerCase()],
                      content && styles.withContent,
                      styles.stickyContent
                    )}>
                    <StandardHeroContent
                      _id={_id}
                      _contentTypeId={_contentTypeId}
                      internalTitle={internalTitle}
                      textJustification={textJustification}
                      title={title}
                      subtitle={subtitle}
                      logo={logo}
                      supertitle={supertitle}
                      bottomImages={bottomImages}
                      body={body}
                      button={button}
                      disclaimerText={disclaimerText}
                      hasForm
                    />
                  </div>
                </>
              ) : (
                <StandardHeroContent
                  _id={_id}
                  _contentTypeId={_contentTypeId}
                  internalTitle={internalTitle}
                  textJustification={textJustification}
                  title={title}
                  subtitle={subtitle}
                  logo={logo}
                  supertitle={supertitle}
                  bottomImages={bottomImages}
                  body={body}
                  button={button}
                  disclaimerText={disclaimerText}
                />
              )}
              {content ? (
                <div className={cx('px-3', styles.moduleForm, styles.content)}>
                  <ContentModule contentTypeId={content._contentTypeId} fields={content} />
                </div>
              ) : null}
              {!!trim(scrollText) && height === 'Full' && (
                <div className={styles.scrollText}>
                  <ScrollText
                    scrollText={scrollText}
                    textTheme={textTheme}
                    scrollTextTheme={scrollTextTheme}
                    {...sidekicker('Scroll Text')}
                  />
                </div>
              )}
              {form?.formSettings && (
                <div
                  className={cx(
                    'standard-hero__module-form',
                    `standard-hero__${formStyle.toLowerCase()}-form`,
                    styles.formWrap,
                    styles[`form-${formStyle}-${formAlignment}`.toLowerCase()],
                    styles[`form-${lowerCase(formAlignment)}`]
                  )}
                  {...sidekicker('Form')}>
                  <ModuleForm
                    key={`${form._id}_${_id}`}
                    formStyle={form.formStyle}
                    _id={form._id}
                    internalTitle={form.internalTitle}
                    anchor={form.anchor && form.anchor.toLowerCase()}
                    _contentTypeId={form._contentTypeId}
                    formSettings={form.formSettings}
                    backgroundColor={form.backgroundColor}
                    headerTextModule={form.headerTextModule}
                    submitButtonLabel={form.submitButtonLabel}
                    successContent={form.successContent}
                    image={form.image}
                    links={form.links}
                    disclaimer={form.disclaimer}
                    customized
                    formLocation="hero"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </ErrorBoundary>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    ...theme.overrides?.Section?.root
  }
}));

const ThemedStandardHero = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { _id, _contentTypeId, internalTitle } = props;
  const { sidekicker } = sidekickInit({ _id, _contentTypeId, internalTitle });

  return (
    <Box
      className={classes.root}
      {...theme.styleOverrides?.Section?.root}
      {...sidekicker('Standard Hero')}
      data-csk-entry-id={_id}>
      <StandardHero {...props} />
    </Box>
  );
};

StandardHero.propTypes = StandardHeroPropTypes;
StandardHero.defaultProps = {
  imageTablets: null,
  imageMobiles: null,
  videoDesktop: null,
  videoMobile: null,
  form: null,
  formAlignment: null,
  anchor: '',
  backgroundColor: null,
  bottomImages: null,
  content: null,
  disclaimerTextString: null,
  eventName: null
};

ThemedStandardHero.propTypes = StandardHeroPropTypes;
ThemedStandardHero.defaultProps = {
  imageTablets: null,
  imageMobiles: null,
  videoDesktop: null,
  videoMobile: null,
  form: null,
  formAlignment: null,
  anchor: '',
  backgroundColor: null,
  bottomImages: null,
  content: null,
  disclaimerTextString: null,
  eventName: null
};

export default ThemedStandardHero;
