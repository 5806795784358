/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import startCase from 'lodash/startCase';
import sidekickInit from '../../utils/sidekick/init';
import styles from './StandardHero.module.scss';
import { CtaButtons } from '../Hero/SharedElements';
import Image, { ImagePropTypes } from '../Image';
import { ElementLinkPropTypes } from '../ElementLink';
import ErrorBoundary from '../ErrorBoundary';
import TradeMark from '../../generics/TradeMark';
import accents from '../../utils/accents';

export const StandardHeroContentPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  internalTitle: PropTypes.string.isRequired,
  textJustification: PropTypes.oneOf(['left', 'center', 'right']).isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  subtitle: PropTypes.string,
  supertitle: PropTypes.string,
  logo: PropTypes.shape(ImagePropTypes),
  button: PropTypes.arrayOf(PropTypes.shape(ElementLinkPropTypes)),
  disclaimerText: PropTypes.string,
  bottomImages: PropTypes.arrayOf(PropTypes.shape(ImagePropTypes)),
  hasForm: PropTypes.bool
};

const StandardHeroContent = (props) => {
  const {
    _id,
    _contentTypeId,
    internalTitle,
    textJustification,
    title,
    subtitle,
    logo,
    supertitle,
    body,
    button,
    disclaimerText,
    hasForm,
    bottomImages
  } = props;
  const { lang } = useTranslation();
  const { sidekicker } = sidekickInit({ _id, _contentTypeId, internalTitle });
  return (
    <ErrorBoundary>
      <div
        className={cx(
          !hasForm &&
            (textJustification === 'left' || textJustification === 'right') &&
            'col-sm-11 col-lg-9 col-xl-8',
          'col-12'
        )}
      >
        {!!supertitle?.trim() && (
          <h3
            data-testid="StandardHero-supertitle"
            className={cx('h3', 'hero-supertitle', styles['hero-supertitle'])}
            {...sidekicker('Supertitle')}
          >
            {supertitle}
          </h3>
        )}
        {title && (
          <h1
            data-testid="StandardHero-title"
            {...sidekicker('Title')}
            className={cx(
              'h1',
              'hero-title',
              styles.title,
              logo && 'mb-0',
              lang === 'cn' && styles.titleCn,
              lang === 'hk' && styles.titleHk,
              [{ accents: accents(title) }]
            )}
          >
            <TradeMark>{title.split('\\n').join('\n')}</TradeMark>
          </h1>
        )}
        {logo && (
          <Image
            testId="StandardHero-logo"
            className={styles.logo}
            image={logo}
            columns={6}
            {...sidekicker('Logo')}
          />
        )}
        {subtitle && (
          <h2
            data-testid="StandardHero-subtitle"
            className={cx('h2', 'hero-subtitle', styles.subtitle, [{ accents: accents(subtitle) }])}
            {...sidekicker('Subtitle')}
          >
            <TradeMark>{subtitle}</TradeMark>
          </h2>
        )}
        {body && (
          <p
            data-testid="StandardHero-body"
            className={cx('hero-bodytext', `justify-${textJustification}`, styles.bodyText)}
            {...sidekicker('Body')}
          >
            <TradeMark bodyContent>{body}</TradeMark>
          </p>
        )}
        {bottomImages && (
          <div className={styles.bottomImages}>
            {bottomImages.map((image) => (
              <Image
                key={`bottom-image-${image.title}`}
                testId="StandardHero-BottomImage"
                className={styles.bottomImage}
                image={image}
              />
            ))}
          </div>
        )}
        <CtaButtons button={button} contentOffset={startCase(textJustification)} />
        {disclaimerText && (
          <p
            data-testid="StandardHero-Disclaimer-Text"
            className={cx(
              'hero-disclaimerText',
              `justify-${textJustification}`,
              styles.disclaimerText
            )}
            {...sidekicker('Disclaimer Text')}
          >
            <TradeMark>{disclaimerText}</TradeMark>
          </p>
        )}
      </div>
    </ErrorBoundary>
  );
};

StandardHeroContent.propTypes = StandardHeroContentPropTypes;
StandardHeroContent.defaultProps = {
  supertitle: null,
  subtitle: null,
  logo: null,
  body: null,
  button: null,
  hasForm: false,
  bottomImages: null,
  disclaimerText: null
};

export default StandardHeroContent;
